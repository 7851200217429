import React, { Component } from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import Navbar from 'react-bulma-components/lib/components/navbar';
import Container from 'react-bulma-components/lib/components/container';
import styled, { css } from 'styled-components';
import logo from './logo.png';
import UserIcon from '../../components/Icons/UserIcon';
import CartIcon from '../../components/Icons/CartIcon';
import SearchIcon from '../../components/Icons/SearchIcon';
import Search from '../../components/Search';

const NavbarItem = styled(Navbar.Item)`
    font-weight: 600;
`;

const NavbarDropdown = styled(Navbar.Dropdown)`
    font-weight: normal;
`;

const CustomNavbar = styled(Navbar)``;

const visibilityMixin = css`
    ${props => props.hidden && `visibility: hidden;  width: 0; `};
`;

const NavSearchIcon = styled(({ hidden, ...restProps }) => (
    <SearchIcon {...restProps} />
))`
    ${visibilityMixin}
    cursor: pointer;
`;

const SearchBox = styled(({ hidden, ...restProps }) => (
    <Search {...restProps} />
))`
    ${visibilityMixin}
`;

export default class TopNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            searchOpen: false,
        };
    }

    toggleMenu = () => {
        this.setState(prevState => ({
            open: !prevState.open,
        }));
    };

    onSearchIconClick = () => {
        this.setState(prevState => ({
            searchOpen: !prevState.searchOpen,
        }));
    };

    handleSearchReset = () => {
        this.setState({ searchOpen: false });
    };

    render() {
        return (
            <StaticQuery
                query={graphql`
                    query {
                        allWordpressWpApiMenusMenusItems(
                            filter: { slug: { eq: "main" } }
                        ) {
                            edges {
                                node {
                                    name
                                    count
                                    items {
                                        order
                                        title
                                        url
                                        object_slug
                                        wordpress_id
                                    }
                                }
                            }
                        }
                    }
                `}
                render={data => {
                    const menu =
                        data.allWordpressWpApiMenusMenusItems.edges[0].node
                            .items;

                    return (
                        <CustomNavbar active={this.state.open}>
                            <Container>
                                <Navbar.Brand>
                                    <Navbar.Item renderAs="a" href="#">
                                        <img
                                            src={logo}
                                            alt="turkish-lamp-wholesaler-logo"
                                            width="117"
                                            height="41"
                                        />
                                    </Navbar.Item>
                                    <Navbar.Burger onClick={this.toggleMenu} />
                                </Navbar.Brand>
                                <Navbar.Menu>
                                    <Navbar.Container>
                                        {menu.map(menuItem => {
                                            return !menuItem.wordpress_children ? (
                                                <NavbarItem
                                                    as={Link}
                                                    to={menuItem.url}
                                                    key={menuItem.wordpress_id}
                                                    className="navbar-item"
                                                    dangerouslySetInnerHTML={{
                                                        __html: menuItem.title,
                                                    }}
                                                />
                                            ) : (
                                                <NavbarItem
                                                    renderAs="div"
                                                    hoverable={true}
                                                    dropdown={true}
                                                    key={menuItem.wordpress_id}
                                                >
                                                    <Navbar.Link
                                                        renderAs={Link}
                                                        to={menuItem.url}
                                                        className="navbar-link"
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                menuItem.title,
                                                        }}
                                                    />
                                                    <NavbarDropdown renderAs="div">
                                                        {menuItem.wordpress_children.map(
                                                            subItem => (
                                                                <Navbar.Item
                                                                    href={
                                                                        subItem.url
                                                                    }
                                                                    key={
                                                                        subItem.wordpress_id
                                                                    }
                                                                    dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            subItem.title,
                                                                    }}
                                                                />
                                                            )
                                                        )}
                                                    </NavbarDropdown>
                                                </NavbarItem>
                                            );
                                        })}
                                    </Navbar.Container>
                                    <Navbar.Container position="end">
                                        <Navbar.Item href="#">
                                            <UserIcon />
                                        </Navbar.Item>
                                        <Navbar.Item href="#">
                                            <CartIcon />
                                        </Navbar.Item>
                                        <Navbar.Item renderAs="div">
                                            <NavSearchIcon
                                                onClick={this.onSearchIconClick}
                                                hidden={this.state.searchOpen}
                                            />
                                            <SearchBox
                                                onReset={this.handleSearchReset}
                                                onBlur={this.handleSearchReset}
                                                hidden={!this.state.searchOpen}
                                                width={
                                                    this.state.searchOpen
                                                        ? 'calc(20rem)'
                                                        : 0
                                                }
                                            />
                                        </Navbar.Item>
                                    </Navbar.Container>
                                </Navbar.Menu>
                            </Container>
                        </CustomNavbar>
                    );
                }}
            />
        );
    }
}
