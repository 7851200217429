import React from 'react';
import Helmet from 'react-helmet';

const SEO = () => (
    <Helmet>
        <link
            rel="stylesheet"
            href="https://cdn.jsdelivr.net/npm/instantsearch.css@7.0.0/themes/algolia-min.css"
        />
    </Helmet>
);

export default SEO;
