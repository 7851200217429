import React from 'react';
import algoliasearch from 'algoliasearch/lite';
import styled from 'styled-components';
import {
    InstantSearch,
    SearchBox,
    Hits,
    Configure,
    Snippet,
    connectStateResults,
    Index,
    PoweredBy,
} from 'react-instantsearch-dom';

const searchClient = algoliasearch(
    'UPYFER7CHA',
    '8313ef70cd7aad9d1bdbc996a11c37bb'
);

const HeaderTitle = styled.div`
    color: #1a1a1a;
    padding: 1rem 0rem 0.5rem 0;
    margin: 0 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    font-weight: bold;
    font-size: 14px;
`;

const HitsContainer = styled.div`
    /* @media only screen and (max-width: 980px) {
        width: 100%;
    } */
    width: ${props => props.width};
    overflow: hidden;
    background: white;
    z-index: 2;
    position: absolute;
    background-color: #fff;
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25), 0 0 1px rgba(0, 0, 0, 0.35);
    padding-bottom: 1rem;
`;

const HitPicture = styled.div`
    float: left;
    margin-right: 5px;
    margin-bottom: 5px;
    img {
        border-radius: 3px;
        width: 32px;
        height: 32px;
    }
`;

const HitName = styled.div`
    font-weight: bold;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const HitDescription = styled.div`
    color: #63676d;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    .ais-Snippet-highlighted {
        padding: 0 0 1px;
        background: inherit;
        box-shadow: inset 0 -2px 0 0 rgba(69, 142, 225, 0.8);
        color: inherit;
    }
`;

const PoweredByContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 2rem 1rem 0.5rem 1rem;
`;

const A = styled.a`
    color: initial;
`;

const Hit = props => {
    return (
        <A href={props.hit.permalink}>
            {!Array.isArray(props.hit.images) && (
                <HitPicture>
                    <img
                        src={props.hit.images.thumbnail.url}
                        align="left"
                        alt={props.hit.post_title}
                    />
                </HitPicture>
            )}

            <div className="suggestion-post-attributes">
                <HitName>
                    <Snippet attribute="post_title" hit={props.hit} />
                </HitName>
                <HitDescription>
                    <Snippet attribute="content" hit={props.hit} />
                </HitDescription>
            </div>
        </A>
    );
};

const Results = connectStateResults(({ searchState, width }) =>
    searchState && searchState.query ? (
        <HitsContainer className="hits-container" width={width}>
            <Index indexName="wp_posts_page">
                <HeaderTitle>Pages</HeaderTitle>
                <Hits hitComponent={Hit} />
            </Index>
            <HeaderTitle>Posts</HeaderTitle>
            <Hits hitComponent={Hit} />
            <PoweredByContainer>
                <PoweredBy />
            </PoweredByContainer>
        </HitsContainer>
    ) : null
);

const handleBlur = () => {
    console.log('this is getting triggered');
};

const Search = ({ width, placeholder, onReset, onBlur, ...restProps }) => (
    <InstantSearch indexName="wp_searchable_posts" searchClient={searchClient}>
        <Configure hitsPerPage={5} />
        <SearchBox
            {...restProps}
            translations={{ placeholder }}
            onReset={onReset}
            onBlur={onBlur}
        />
        <Results width={width} />
    </InstantSearch>
);

Search.defaultProps = {
    width: 'calc(20rem)',
    placeholder: 'Search here...',
};

export default Search;
