import React, { Component } from 'react';
import styled from 'styled-components';
import '../sass/app.scss';
import Container from 'react-bulma-components/lib/components/container';
//import Content from 'react-bulma-components/lib/components/content';
import TopNav from '../containers/TopNav';
import Seo from '../components/Seo';

const Main = styled.main`
    padding: 0 3rem;
    @media only screen and (max-width: 1088px) {
        padding: 0 1.5rem;
    }
`;

const PageLayout = styled.div`
    display: flex;
    @media only screen and (max-width: 980px) {
        flex-wrap: wrap;
    }
`;

const MainContent = styled.div`
    flex-grow: 1;
    flex-shrink: 1;
    padding-bottom: 3rem;
    ${props =>
        props.page_layout !== 'full-width-content' &&
        'border-right: 1px solid #efefef; padding-right: 5rem;'};
    @media only screen and (max-width: 980px) {
        padding-right: 0;
        border-right: 0;
    }
`;

class Layout extends Component {
    render() {
        return (
            <React.Fragment>
                <Seo />
                <TopNav />
                <Main>
                    <Container className="is-fluid">
                        <PageLayout>
                            <MainContent page_layout={this.props.page_layout}>
                                {this.props.children}
                            </MainContent>
                        </PageLayout>
                    </Container>
                </Main>
            </React.Fragment>
        );
    }
}

Layout.defaultProps = {
    hideSearch: false,
};

export default Layout;
